import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
const VueKatex = require('@hsorby/vue3-katex');
import 'katex/dist/katex.min.css';
import Toast, { POSITION, PluginOptions } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";


var toastOptions : PluginOptions = {
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
  position: POSITION.TOP_CENTER,
}


var app = createApp(App).use(router).use(VueKatex, {
    globalOptions: {
    //... Define globally applied KaTeX options here
    }
  }).use(Toast, toastOptions).mount('#app')

  