import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import axios from 'axios';
import config from '@/config';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'MathForE1'
    },
    component: HomeView
  },
  {
    path: '/re',
    name: 'redirect',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    beforeEnter: (to, from, next) => {
      // get meet link from api
      axios.get(config.api + '/api/redirect/meet').then(response => {
        let newLocation = response.data.href;
        if(!newLocation) {
          next('/')
        } else {
          window.open(newLocation.startsWith('https://') ? newLocation : 'https://' + newLocation)
        }
      }).catch(err => {
        next('/')
      })
    }
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    beforeEnter: (to, from, next) => {
      var token = localStorage.getItem('accessToken');
      var role = localStorage.getItem('role');
      if(token && role == 'mathgod'){
        next('/admin');
      } if(token) {
        next('/myaccount');
      } else {
        next();
      }
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminView.vue'),
    beforeEnter: (to, from, next) => {
      var token = localStorage.getItem('accessToken');
      var role = localStorage.getItem('role');

      if(token && role == 'mathgod'){
        next();
      } else if(token) {
        location.href = 'https://www.youtube.com/watch?v=-HdqqqiY6dk&ab_channel=MemeFountain';
        return;
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/myaccount',
    name: 'myaccount',
    component: () => import(/* webpackChunkName: "about" */ '../views/StudentView.vue'),
    beforeEnter: (to, from, next) => {
      var token = localStorage.getItem('accessToken');
      var role = localStorage.getItem('role');

      if(token && role == 'mathgod'){
        next('/admin');
      } else if(token) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/test',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestView.vue')
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: 'Noutati'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsView.vue')
  },
  {
    path: '/informatii',
    name: 'informatii',
    meta: {
      title: 'Informatii'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title: 'Contact'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  var token = localStorage.getItem('accessToken');
  var lastVerified = localStorage.getItem('lastVerified');
  if(!lastVerified || isNaN(Number(lastVerified))){
    lastVerified = '0';
  }

  if(token && Number(lastVerified) + 1000 * 60 * 60 * 24 < Date.now()){
    next();
  }
  next();
});

export default router
